const Comp = () => (
  <>
			<div id="page-title" className="page-title">
				<div className="container">
					<div className="clearfix">
						<div className="grid_12">
							<div className="page-title-holder">
								<h1>Ron Wilson in Architectural Digest</h1>
							</div>
							
							<ul className="breadcrumbs">
								<li><a href="index.php">Home</a></li>
								<li><a href="press.php">Press</a></li>
								<li>Architectural Digest</li>
							</ul>
							
						</div>
					</div>
				</div>
			</div>
			
			
			
			
			<div id="content-wrapper" className="content-wrapper">
				<div className="container">
					
					<div className="clearfix">
						<div className="grid_12">
							
			           
			            
							
			            <div className="clearfix">
			            	<div className="grid_5 alpha">
			            		
									<div className="flexslider project-thumbs flexslider__nav-on">
										<ul className="slides">
											<li><img src="images/press/1993-05-m.jpg" height="500" width="" alt=""/></li>
										</ul>
									</div>
									
			            	</div>
			            	<div className="grid_6 omega">
			            		
			            		<div className="project-desc project-desc__single">
										<h3 className="title"><a href="#">Architectural Digest</a></h3>
										<span className="desc">May, 1993</span>
										<div className="project-excerpt">
											<p>Hope Ranch of Santa Barbara, California was the site of an Italian villa featured in the May 1993 Architectural Digest. Pale Mediterranean colors and an eclectic interplay of textures and surfaces became the theme. A comfortable sophistication was highly valued by these clients. (Pages 202 -209)</p> 
										</div>
										
										
									</div>
									
			            	</div>
			            </div>

			            <div className="hr"></div>


			            
						</div>
					</div>

				</div>
			</div>
  </>
);

export default Comp;
