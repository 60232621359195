const Comp = () => (
  <>
    <div id="page-title" className="page-title">
      <div className="container">
        <div className="clearfix">
          <div className="grid_12">
            <div className="page-title-holder">
              <h1>Ron Wilson Interiors Design Portfolio</h1>
            </div>

							<ul className="breadcrumbs">
              <li><a href="index.php">Home</a></li>
              <li>Portfolio</li>
            </ul>

						</div>
        </div>
      </div>
    </div>

    <div id="content-wrapper" className="content-wrapper">
      <div className="container">
        <div className="clearfix">
          <div className="grid_12">

            <div className="clearfix">

								<ul className="project-feed-filter">
                <li><a href="#" className="current" data-filter="*">All</a></li>
                <li><a href="#" data-filter=".foyers">Foyers</a></li>
                <li><a href="#" data-filter=".living-rooms">Living Rooms</a></li>
                <li><a href="#" data-filter=".dining-rooms">Dining Rooms</a></li>
                <li><a href="#" data-filter=".kitchens">Kitchens</a></li>
                <li><a href="#" data-filter=".bedrooms">Bedrooms</a></li>
                <li><a href="#" data-filter=".bath-areas">Bath Areas</a></li>
                <li><a href="#" data-filter=".exteriors">Exteriors</a></li>
                <li><a href="#" data-filter=".additional">Additional</a></li>

              </ul>

							</div>

            <div className="clearfix">

								<div className="project-feed">



                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0052.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0052.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bath-areas">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0053.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0053.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0054.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0054.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0055.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0055.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bedrooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0056.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0056.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>




                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0044.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0044.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>

                <div className="grid_4 project-item bedrooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0049.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0049.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>

                <div className="grid_4 project-item exteriors">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0050.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0050.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item exteriors">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0051.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0051.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>


                <div className="grid_4 project-item kitchens">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0000.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0000.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0001.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0001.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item exteriors">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0002.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0002.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0003.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0003.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0004.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0004.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0005.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/ron-wilson-interiors-portfolio_0005.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0006.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0006.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item dining-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0007.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0007.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0008.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0008.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0009.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0009.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0010.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0010.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bedrooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0011.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/ron-wilson-interiors-portfolio_0011.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0012.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0012.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0013.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0013.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bedrooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0014.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0014.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bath-areas">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0015.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0015.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0016.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0016.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bath-areas">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0017.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0017.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bath-areas">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0018.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0018.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item exteriors">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0019.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0019.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item kitchens">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0020.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0020.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0021.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0021.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bath-areas">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0022.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0022.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item dining-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0023.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0023.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0024.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0024.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0025.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0025.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bath-areas">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0026.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0026.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0027.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0027.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0028.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0028.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0029.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/ron-wilson-interiors-portfolio_0029.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item exteriors">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0030.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/ron-wilson-interiors-portfolio_0030.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bedrooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0031.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0031.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bedrooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0032.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0032.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0033.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0033.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item bath-areas">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0034.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0034.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0035.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0035.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0036.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0036.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0037.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/ron-wilson-interiors-portfolio_0037.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item exteriors">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0038.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0038.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0039.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0039.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0040.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0040.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item kitchens">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0041.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0041.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0042.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0042.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item foyers">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0043.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0043.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>

                <div className="grid_4 project-item living-rooms">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0045.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0045.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item kitchens">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0046.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0046.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0047.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0047.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_4 project-item additional">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0048.jpg" height="187" width="280" alt="" />
                      <div className="overlay">
                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0048.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
                    </figure>
                  </div>
                </div>


              </div>
							</div>


          </div>
        </div>
      </div>
    </div>
  </>
);

export default Comp;
