import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import About from './About';
import Services from './Services';
import Portfolio from './Portfolio';
import Press from './Press';
import History from './History';
import Contact from './Contact';
import Ad_1975_05 from './ad-1975-05';
import Ad_1978_12 from './ad-1978-12';
import Ad_1979_06 from './ad-1979-06';
import Ad_1989_05 from './ad-1989-05';
import Ad_1990_08 from './ad-1990-08';
import Ad_1991_10 from './ad-1991-10';
import Ad_1993_05 from './ad-1993-05';
import Ad_1998_07 from './ad-1998-07';
import Ad_2000_01 from './ad-2000-01';
import Ad_2002_08 from './ad-2002-08';
import Ad_2002_12 from './ad-2002-12';
import Ad_2006_06 from './ad-2006-06';
import Ad_2007_08 from './ad-2007-08';
import Ad_2007_12 from './ad-2007-12';

const App = () => {
  return (
    <Router>
      <>
        <Header />
        <Switch>
          <Route path="/about.php">
            <About />
          </Route>
          <Route path="/services.php">
            <Services />
          </Route>
          <Route path="/portfolio.php">
            <Portfolio />
          </Route>
          <Route path="/press.php">
            <Press/>
          </Route>
          <Route path="/history.php">
            <History/>
          </Route>
          <Route path="/contact.php">
            <Contact />
          </Route>

          <Route path="/ad-1975-05.php">
            <Ad_1975_05 />
          </Route>
          <Route path="/ad-1978-12.php">
            <Ad_1978_12 />
          </Route>
          <Route path="/ad-1979-06.php">
            <Ad_1979_06 />
          </Route>
          <Route path="/ad-1989-05.php">
            <Ad_1989_05 />
          </Route>
          <Route path="/ad-1990-08.php">
            <Ad_1990_08 />
          </Route>
          <Route path="/ad-1991-10.php">
            <Ad_1991_10 />
          </Route>
          <Route path="/ad-1993-05.php">
            <Ad_1993_05 />
          </Route>
          <Route path="/ad-1998-07.php">
            <Ad_1998_07 />
          </Route>
          <Route path="/ad-2000-01.php">
            <Ad_2000_01 />
          </Route>
          <Route path="/ad-2002-08.php">
            <Ad_2002_08 />
          </Route>
          <Route path="/ad-2002-12.php">
            <Ad_2002_12 />
          </Route>
          <Route path="/ad-2006-06.php">
            <Ad_2006_06 />
          </Route>
          <Route path="/ad-2007-08.php">
            <Ad_2007_08 />
          </Route>
          <Route path="/ad-2007-12.php">
            <Ad_2007_12 />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </>
    </Router>
  );
}

export default App;
