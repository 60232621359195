const Comp = () => (
  <>
			<div id="page-title" className="page-title">
				<div className="container">
					<div className="clearfix">
						<div className="grid_12">
							<div className="page-title-holder">
								<h1>Ron Wilson in Architectural Digest</h1>
							</div>
							
							<ul className="breadcrumbs">
								<li><a href="index.php">Home</a></li>
								<li><a href="press.php">Press</a></li>
								<li>Architectural Digest</li>
							</ul>
							
						</div>
					</div>
				</div>
			</div>
			
			
			
			
			<div id="content-wrapper" className="content-wrapper">
				<div className="container">
					
					<div className="clearfix">
						<div className="grid_12">
							
			           
			            
							
			            <div className="clearfix">
			            	<div className="grid_5 alpha">
			            		
									<div className="flexslider project-thumbs flexslider__nav-on">
										<ul className="slides">
											<li><img src="images/press/1975-05-m.jpg" height="500" width="" alt=""/></li>
										</ul>
									</div>
									
			            	</div>
			            	<div className="grid_6 omega">
			            		
			            		<div className="project-desc project-desc__single">
										<h3 className="title"><a href="#">Architectural Digest</a></h3>
										<span className="desc">May, 1975</span>
										<div className="project-excerpt">
											<p>As early as the 1970’s, Ron Wilson began to have his work featured in Architectural Digest. The Italianate Bel Air estate belonging to Sonny and Cher was shown in the May/June 1974 and served as the inspiration for his own residence that once belonged to Cole Porter. He made use of a warm palette with abundant wood and numerous hand-finishes in the stately Hollywood Hills home with ocean and city views (Pages 64 – 69)</p> 
										</div>
										
										
									</div>
									
			            	</div>
			            </div>

			            <div className="hr"></div>


			            
						</div>
					</div>

				</div>
			</div>
  </>
);

export default Comp;
