const Footer = () => (
  <footer id="footer" className="footer">
				
				<div className="widgets-footer">
					<div className="container clearfix">
						<div className="grid_12">
	
							<div className="social-widget widget widget__footer">
								<div className="widget-content">
									<ul className="social-links">
										<li className="link-facebook"><a href="http://www.facebook.com/pages/Ron-Wilson-Interiors/233200440052772"><i className="icon-facebook"></i></a></li>
										<li className="link-google"><a href="https://plus.google.com/113275944613889114546" rel="publisher"><i className="icon-google-plus"></i></a></li>

										<li className="link-flickr"><a href="http://www.flickr.com/photos/103405748@N06/"><i className="icon-flickr"></i></a></li>
									</ul>
								</div>
							</div>
			
						</div>						
						
			
					</div>
				</div>
				<div className="copyright">
					<div className="container clearfix">
						<div className="grid_12 mobile-nomargin">
							<div className="clearfix">
								<div className="copyright-primary">
                  Copyright &copy; Ron Wilson Interiors, Inc. {new Date().getFullYear()}. All Rights Reserved. <span className="separator"></span>
								</div>
								<div className="copyright-secondary">
									Invincible Creative <a href="http://www.invinciblecreative.com">Website Development, San Diego, CA</a>
								</div>
							</div>
						</div>
					</div>
				</div>

			</footer>
);

export default Footer;
