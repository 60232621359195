const Comp = () => (
  <>
    <div id="page-title" className="page-title">
      <div className="container">
        <div className="clearfix">
          <div className="grid_12">
            <div className="page-title-holder">
              <h1>Ron Wilson Interiors in the Press</h1>
            </div>

							<ul className="breadcrumbs">
              <li><a href="index.php">Home</a></li>
              <li>Press</li>
            </ul>

						</div>
        </div>
      </div>
    </div>

    <div id="content-wrapper" className="content-wrapper">
      <div className="container">
        <div className="clearfix">
          <div className="grid_12">



            <div className="clearfix">

								<div className="project-feed">
                <div className="grid_3 project-item design logo">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/2007-12.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-2007-12.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item design graphic">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/2007-08.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-2007-08.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item graphic">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/2006-06.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-2006-06.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item illustration logo">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/2002-12.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-2002-12.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item illustration logo">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/2002-08.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-2002-08.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item design graphic">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/2000-01.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-2000-01.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item illustration logo">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/1998-07.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-1998-07.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item illustration logo">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/1993-05.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-1993-05.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item graphic webdesign">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/1991-10.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-1991-10.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>
                  </div>
                </div>
                <div className="grid_3 project-item logo graphic">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/1990-08.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-1990-08.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item logo graphic">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/1989-05.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-1989-05.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item logo graphic">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/1979-06.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-1979-06.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item logo graphic">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/1978-12.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-1978-12.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>
                <div className="grid_3 project-item logo graphic">
                  <div className="project-item-inner">
                    <figure className="project-img">
                      <img src="images/press/1975-05.jpg" height="147" width="200" alt="" />
                      <div className="overlay">
                        <a href="ad-1975-05.php" className="dlink"><i className="icon-link"></i></a>

                      </div>
                    </figure>

                  </div>
                </div>






              </div>

							</div>


          </div>
        </div>
      </div>
    </div>
  </>
);

export default Comp;
