import { useState, useEffect } from 'react';
import { init, send } from 'emailjs-com';


const Comp = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    subject: '',
    comments: ''
  });
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => init("user_Og9qxwG1RmatbPzbwAVOD"),[]);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.id]: e.target.value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValues);
    send("service_h9fyj2a", "template_9xhiovj", formValues)
      .then(function (response) {
        setFormSuccess(true);
        setFormError(false);
      }, function (error) {
          setFormError(true);
      });
  }
  
  return (
  <>
    <div id="page-title" className="page-title">
      <div className="container">
        <div className="clearfix">
          <div className="grid_12">
            <div className="page-title-holder">
              <h1>Contact Us</h1>
            </div>

            <ul className="breadcrumbs">
              <li><a href="index.php">Home</a></li>
              <li>Contact</li>
            </ul>

          </div>
        </div>
      </div>
    </div>

    <div id="content-wrapper" className="content-wrapper">
      <div className="container">
        <div className="clearfix">
          <div className="grid_6">

            <h3>Our Location</h3>
            <div className="map-wrapper map-wrapper__small">
              <a target="_blank" href="https://goo.gl/maps/arbq2hWQZQ6vwmdc7"><img style={{width: "100%"}} src="images/ronmap.png" /></a>
            </div>

            <h3>Contact Info</h3>
            <ul className="contact-info">
              <li>
                <i className="icon-map-marker"></i> <strong>Address:</strong> 303 North June Street , Los Angeles, CA 90004
              </li>
              <li>
                <i className="icon-phone"></i> <strong>Phone:</strong> <a href="tel:+1-310-276-0666">310.276.0666</a>
              </li>
              <li>
                <i className="icon-copy"></i> <strong>Fax:</strong> <a href="tel:+1-310-276-7291">310.276.7291</a>
              </li>
              <li>
                <i className="icon-envelope"></i> <strong>Email:</strong> <a href="mailto:info@ronwilsondesigner.com">info@ronwilsondesigner.com</a>
              </li>
              <li>
                <i className="icon-globe"></i><strong>Site:</strong> <a href="http://www.ronwilsoninteriors.com">www.ronwilsoninteriors.com</a>
              </li>
            </ul>

          </div>
          <div className="grid_6">
            <h3>Contact Us</h3>

            <div className="box">

                <form onSubmit={handleSubmit} className="contact-form input-blocks">

                  <div className="field">
                    <label htmlFor="name"><strong>Your Name</strong> (required)</label>
                    <input value={formValues?.name} onChange={handleChange} type="text" name="name" id="name" />
                  </div>
                  <div className="field">
                    <label htmlFor="email"><strong>E-mail</strong> (required)</label>
                    <input value={formValues?.email} onChange={handleChange} type="email" name="email" id="email" />
                  </div>
                  <div className="field">
                    <label htmlFor="subject"><strong>Subject</strong></label>
                    <input value={formValues?.subject} onChange={handleChange} type="text" name="subject" id="subject" />
                  </div>
                  <div className="field">
                    <label htmlFor="contact-message"><strong>Your Message</strong> (required)</label>
                    <textarea value={formValues?.comments} onChange={handleChange} name="comments" id="comments" cols="30" rows="10"></textarea>
                  </div>
                  <div className="button-wrapper">
                    <input type="submit" name="submit" id="submit" value="Send message" />
                  </div>
                  <div id="response"></div>
                </form>
                {formSuccess && <div className='alert alert-success'><i className='icon-flag'></i> Congratulations, {formValues.name} We've received your email. We'll be in touch as soon as we possibly can! </div>}
                {formError && <div className='alert alert-error'><i className='icon-warning-sign'></i> Sorry, there was a problem sending. Please email us directly at <a href="mailto:joe@ronwilsondesigner.com">joe@ronwilsondesigner.com</a></div>}
                    </div>

                  </div>
                </div>
     </div>
   </div>
  </>
)
};

export default Comp;
