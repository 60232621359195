const Comp = () => (
  <>
			<div id="page-title" className="page-title">
				<div className="container">
					<div className="clearfix">
						<div className="grid_12">
							<div className="page-title-holder">
								<h1>Ron Wilson in Architectural Digest</h1>
							</div>
							
							<ul className="breadcrumbs">
								<li><a href="index.php">Home</a></li>
								<li><a href="press.php">Press</a></li>
								<li>Architectural Digest</li>
							</ul>
							
						</div>
					</div>
				</div>
			</div>
			
			
			
			
			<div id="content-wrapper" className="content-wrapper">
				<div className="container">
					
					<div className="clearfix">
						<div className="grid_12">
							
			           
			            
							
			            <div className="clearfix">
			            	<div className="grid_5 alpha">
			            		
									<div className="flexslider project-thumbs flexslider__nav-on">
										<ul className="slides">
											<li><img src="images/press/1989-05-m.jpg" height="500" width="" alt=""/></li>
										</ul>
									</div>
									
			            	</div>
			            	<div className="grid_6 omega">
			            		
			            		<div className="project-desc project-desc__single">
										<h3 className="title"><a href="#">Architectural Digest</a></h3>
										<span className="desc">May, 1989</span>
										<div className="project-excerpt">
											<p>In this celebrated issue of AD, Ron created a very special contemporary home that served as a Big City getaway for his clients. The clean lines & modern feel of this high rise home are in stark contrast to the client’s primary residence, which Ron designed to feel like an authentic Mediterranean villa transported to Santa Barbara. The May 1989 issue is noted for featuring many “legends” of design, all in one issue, including Steve Chase, Kalef Alaton, Anthony Hail, Frank Penino, Jerry Leen (Dennis & Leen,) Tony Duquette and of course, Ron Wilson. (Pages 270-275)</p> 
										</div>
										
										
									</div>
									
			            	</div>
			            </div>

			            <div className="hr"></div>


			            
						</div>
					</div>

				</div>
			</div>
  </>
);

export default Comp;
