const Comp = () => (
  <>
			<div id="page-title" className="page-title">
				<div className="container">
					<div className="clearfix">
						<div className="grid_12">
							<div className="page-title-holder">
								<h1>Ron Wilson in Architectural Digest</h1>
							</div>
							
							<ul className="breadcrumbs">
								<li><a href="index.php">Home</a></li>
								<li><a href="press.php">Press</a></li>
								<li>Architectural Digest</li>
							</ul>
							
						</div>
					</div>
				</div>
			</div>
			
			
			
			
			<div id="content-wrapper" className="content-wrapper">
				<div className="container">
					
					<div className="clearfix">
						<div className="grid_12">
							
			           
			            
							
			            <div className="clearfix">
			            	<div className="grid_5 alpha">
			            		
									<div className="flexslider project-thumbs flexslider__nav-on">
										<ul className="slides">
											<li><img src="images/press/2002-08-m.jpg" height="500" width="" alt=""/></li>
										</ul>
									</div>
									
			            	</div>
			            	<div className="grid_6 omega">
			            		
			            		<div className="project-desc project-desc__single">
										<h3 className="title"><a href="#">Architectural Digest</a></h3>
										<span className="desc">August, 2002</span>
										<div className="project-excerpt">
											<p>Cher’s Italian Renaissance style villa in Malibu was featured on the August 2002 cover. This was Cher’s favorite home, she was very involved in every detail from concept to final furnishings. Venetian style villa’s décor is eclectic with Gothic Revival religious pieces, Victorian tufted furniture, Egyptian sculptures, and 19th century English cabinets in a high style that only Ron Wilson could accomplish. (Pages 86 – 93)</p> 
										</div>
										
										
									</div>
									
			            	</div>
			            </div>

			            <div className="hr"></div>


			            
						</div>
					</div>

				</div>
			</div>
  </>
);

export default Comp;
