const Header = () => {

	const showBanner = new Date().getTime() < 1622111100 * 1000;
	
	return (
  <>
		{ showBanner ? <div style={{ padding: "12px", textAlign: "center", width: "100vw", background: "#e5e4df url(../images/pattern-bg2.gif)"}}>
			<h1>Virtual Homes Tour</h1>
			<h6>Windsor Square - Hancock Park Historical Society
			Presents</h6>
			<h4>May 26, 2021 at 7pm</h4>
			<a target="_blank" href="https://windsorsquarehancockpark.com/2021/01/history-of-303-n-june-street/"><button>Join Us</button></a>
	</div> : null }
 	<div id="top-bar" className="top-bar">
    <div className="container clearfix">
      <div className="grid_6 top-txt hidden-phone">
      &nbsp;
      </div>
      <div className="grid_6 acc-txt mobile-nomargin">
      &nbsp;
      </div>
    </div>
  </div>
			
			
		<div className="main-box">
			<header id="header" className="header">
				
				<div className="container clearfix">
					<div className="grid_2 mobile-nomargin">
						<div id="logo" className="logo">
							<a href="index.php"><img src="images/logo.png" alt="Ron Wilson Interiors" /></a> 
						</div>
					</div>
					
					<div className="grid_10 mobile-nomargin">
						<nav className="primary clearfix">
							<ul className="sf-menu">
								<li className="<?php echo $home; ?>"><a href="index.php">Home</a>
								</li>
								<li className="<?php echo $about; ?>"><a href="about.php">About</a>
								</li>
								<li className="<?php echo $services; ?>"><a href="services.php">Services</a>
								</li>
								<li className="<?php echo $portfolio; ?>"><a href="portfolio.php">Portfolio</a>
								</li>
								<li className="<?php echo $press; ?>"><a href="press.php">Press</a>
								</li>
								<li className="<?php echo $history; ?>"><a href="history.php">History</a>
								</li>
								
								<li className="<?php echo $contact; ?>"><a href="contact.php">Contact</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				
			</header>
      </div>
    </>
)
	};

export default Header;
