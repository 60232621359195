import Projects from "./Projects";

const Comp = () => (
  <>
    <div id="page-title" className="page-title">
      <div className="container">
        <div className="clearfix">
          <div className="grid_12">
            <div className="page-title-holder">
              <h1>Services</h1>
            </div>

							<ul className="breadcrumbs">
              <li><a href="index.php">Home</a></li>
              <li>Services</li>
            </ul>

						</div>
        </div>
      </div>
    </div>

    <div id="content-wrapper" className="content-wrapper">
      <div className="container">


        <div className="clearfix">
          <div className="grid_6">

							<div className="flexslider flexslider__nav-on">
              <ul className="slides">
                <li>
                  <img src="images/sliders/RWI_7.jpg" height="462" width="315" alt="" />
							    </li>
                  <li>
                    <img src="images/sliders/RWI_8.jpg" height="462" width="315" alt="" />
							    </li>
                    <li>
                      <img src="images/sliders/RWI_9.jpg" height="462" width="315" alt="" />
							    </li>
							  </ul>
							</div>

						</div>
                <div className="grid_6">
                  <p className="service">Ron Wilson Interiors primarily focuses on complete home interior design projects.
We manage every aspect of the project from space planning, initial design concepts, interior details, furnishing and fabrics.</p>

					<div className="hr hr__double">
                    <div className="hr-first"></div>
                    <div className="hr-second"></div>
                  </div>

						<div className="grid_6 service-quote">

                    <blockquote id="services-quote" className="bq-alt">
                      <p align="center">I want the <a href="#">central object</a> in a room to be <a href="#">the person</a> and everything else just a <a href="#">beautiful background</a>.</p>
                      <cite><strong>Ron Wilson</strong>, Founder</cite>
                    </blockquote>

                  </div>

						</div>
					</div>

					<div className="hr hr__double">
                <div className="hr-first"></div>
                <div className="hr-second"></div>
              </div>

					<Projects />



				</div>
          </div>
  </>
);

export default Comp;
