const Comp = () => (
  <>
    <div id="page-title" className="page-title">
      <div className="container">
        <div className="clearfix">
          <div className="grid_12 mobile-nomargin">
            <div className="page-title-holder">
              <h1>The History of Ron Wilson Interiors</h1>
            </div>

							<ul className="breadcrumbs">
              <li><a href="index.php">Home</a></li>
              <li>History</li>
            </ul>

						</div>
        </div>
      </div>
    </div>

    <div id="content-wrapper" className="content-wrapper">
      <div className="container">
        <div className="clearfix">
          <div className="grid_6">


							<div className="flexslider flexslider__nav-on">
              <ul className="slides">
                <li>
                  <img src="images/sliders/RWI_4.jpg" height="462" width="315" alt=""/>
							    </li>
                  <li>
                    <img src="images/sliders/RWI_5.jpg" height="462" width="315" alt=""/>
							    </li>
                    <li>
                      <img src="images/sliders/RWI_6.jpg" height="462" width="315" alt="" />
							    </li>
							  </ul>
							</div>

						</div>

                <div className="grid_6">

                  <h3>History Behind Ron Wilson Interiors</h3>
                  <p>Ron Wilson was an interior designer for more than 40 years.  Ron had an eye for great style and an unmistakable passion for creating space to inspire those who live in it.  This was his gift, and luckily he discovered it early on in life and followed it with abandon.  Ron Wilson began designing in his early 20’s.  His scholastic background was limited to a high school degree with no formal training in design.  Ron began his early projects decorating model homes for a housing developer in the Valley area of Los Angeles.  From the very beginning, Ron realized the importance of creating the most wile being on a strict budget.</p>

                  <p>His work has been featured in Architectural Digest over 20 times and has been featured on the cover on several occasions.  Other publications include House &amp; Garden, House Beautiful and other design magazines throughout the world.  He was named one of Architectural Digest’s 100 best designers and has lectured on design at the Smithsonian Institute, UCLA and USC.  Ron was proud to sponsor the Ron Wilson Art Gallery at Pepperdine University in Malibu, California, which features contemporary artists.</p>

                  <p>Ron was often referred to as the “Designer to the Stars” because of his long roster of celebrity clients including; Eddie Murphy, Tom Selleck, Michael Landon, Michael Douglas, Kenny Rogers, Don Rickles and Princess Diana.  Last but certainly not least, Ron &amp; his team decorated 19 homes for Cher in the last 30 years.  The intrinsic paradox of working with a woman of her great talent and distinction became the fuel for his inspiration.</p>

                  <p>Ron made it his top priority to impart a sense of value, work ethic and integrity to all of his projects.</p> 
						</div>
            </div>



            <div className="hr"></div>



          </div>
        </div>
  </>
);

export default Comp;
