const Projects = () => (
  <div className="clearfix">
    <div className="clients">
      <div className="grid_12 mobile-nomargin">
        <h3 align="center">Recent Projects</h3>
        <div className="list-carousel carousel__projects">
          <ul id="projects-scroller">
            <li>
              <div className="item-inner">
                <figure className="img-holder">
                  <img src="images/portfolio/ron-wilson-interiors-portfolio_0056.jpg" height="208" width="200" alt="" />
                    <div className="overlay">

                      <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0056.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                    </div>
												</figure>
												
											</div>
										</li>
              <li>
                <div className="item-inner">
                  <figure className="img-holder">
                    <img src="images/portfolio/ron-wilson-interiors-portfolio_0055.jpg" height="208" width="200" alt="" />
                      <div className="overlay">

                        <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0055.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                      </div>
												</figure>
												
											</div>
										</li>
                <li>
                  <div className="item-inner">
                    <figure className="img-holder">
                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0054.jpg" height="208" width="200" alt="" />
                        <div className="overlay">

                          <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0054.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                        </div>
												</figure>
												
											</div>
										</li>
                  <li>
                    <div className="item-inner">
                      <figure className="img-holder">
                        <img src="images/portfolio/ron-wilson-interiors-portfolio_0053.jpg" height="208" width="200" alt="" />
                          <div className="overlay">

                            <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0053.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                          </div>
												</figure>
												
											</div>
										</li>
                    <li>
                      <div className="item-inner">
                        <figure className="img-holder">
                          <img src="images/portfolio/ron-wilson-interiors-portfolio_0052.jpg" height="208" width="200" alt="" />
                            <div className="overlay">

                              <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0052.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                            </div>
												</figure>
												
											</div>
										</li>
                      <li>
                        <div className="item-inner">
                          <figure className="img-holder">
                            <img src="images/portfolio/ron-wilson-interiors-portfolio_0046.jpg" height="208" width="200" alt="" />
                              <div className="overlay">

                                <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0046.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                              </div>
												</figure>
												
											</div>
										</li>
                        <li>
                          <div className="item-inner">
                            <figure className="img-holder">
                              <img src="images/portfolio/ron-wilson-interiors-portfolio_0047.jpg" height="208" width="200" alt="" />
                                <div className="overlay">

                                  <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0047.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                </div>
												</figure>
												
											</div>
										</li>
                          <li>
                            <div className="item-inner">
                              <figure className="img-holder">
                                <img src="images/portfolio/ron-wilson-interiors-portfolio_0048.jpg" height="208" width="200" alt="" />
                                  <div className="overlay">

                                    <a href="images/portfolio/lrg-ron-wilson-interiors-portfolio_0048.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                  </div>
												</figure>
												
											</div>
										</li>
                            <li>
                              <div className="item-inner">
                                <figure className="img-holder">
                                  <img src="images/portfolio/ron-wilson-interiors-portfolio_0001.jpg" height="208" width="200" alt="" />
                                    <div className="overlay">

                                      <a href="images/portfolio/ron-wilson-interiors-portfolio_0001.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                    </div>
												</figure>
												
											</div>
										</li>
                              <li>
                                <div className="item-inner">
                                  <figure className="img-holder">
                                    <img src="images/portfolio/ron-wilson-interiors-portfolio_0007.jpg" height="208" width="200" alt="" />
                                      <div className="overlay">

                                        <a href="images/portfolio/ron-wilson-interiors-portfolio_0007.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                      </div>
												</figure>
												
											</div>
										</li>
                                <li>
                                  <div className="item-inner">
                                    <figure className="img-holder">
                                      <img src="images/portfolio/ron-wilson-interiors-portfolio_0013.jpg" height="208" width="200" alt="" />
                                        <div className="overlay">

                                          <a href="images/portfolio/ron-wilson-interiors-portfolio_0013.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                        </div>
												</figure>
												
											</div>
										</li>
                                  <li>
                                    <div className="item-inner">
                                      <figure className="img-holder">
                                        <img src="images/portfolio/ron-wilson-interiors-portfolio_0014.jpg" height="208" width="200" alt="" />
                                          <div className="overlay">

                                            <a href="images/portfolio/ron-wilson-interiors-portfolio_0014.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                          </div>
												</figure>
												
											</div>
										</li>
                                    <li>
                                      <div className="item-inner">
                                        <figure className="img-holder">
                                          <img src="images/portfolio/ron-wilson-interiors-portfolio_0019.jpg" height="208" width="200" alt="" />
                                            <div className="overlay">

                                              <a href="images/portfolio/ron-wilson-interiors-portfolio_0019.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                            </div>
												</figure>
												
											</div>
										</li>
                                      <li>
                                        <div className="item-inner">
                                          <figure className="img-holder">
                                            <img src="images/portfolio/ron-wilson-interiors-portfolio_0020.jpg" height="208" width="200" alt="" />
                                              <div className="overlay">

                                                <a href="images/portfolio/ron-wilson-interiors-portfolio_0020.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                              </div>
												</figure>
												
											</div>
										</li>
                                        <li>
                                          <div className="item-inner">
                                            <figure className="img-holder">
                                              <img src="images/portfolio/ron-wilson-interiors-portfolio_0025.jpg" height="208" width="200" alt="" />
                                                <div className="overlay">

                                                  <a href="images/portfolio/ron-wilson-interiors-portfolio_0025.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                                </div>
												</figure>
												
											</div>
										</li>
                                          <li>
                                            <div className="item-inner">
                                              <figure className="img-holder">
                                                <img src="images/portfolio/ron-wilson-interiors-portfolio_0034.jpg" height="208" width="200" alt="" />
                                                  <div className="overlay">

                                                    <a href="images/portfolio/ron-wilson-interiors-portfolio_0034.jpg" className="popup-link zoom"><i className="icon-zoom-in"></i></a>
                                                  </div>
												</figure>
												
											</div>
										</li>
										
									</ul>
                                          <div className="carousel-nav">
                                            <a id="prev2" className="prev" href="#"><i className="icon-chevron-left"></i></a>
                                            <a id="next2" className="next" href="#"><i className="icon-chevron-right"></i></a>
                                          </div>
								</div>
							</div>
						</div>
					</div>
);

export default Projects;