const Comp = () => (
  <>
			<div id="page-title" className="page-title">
				<div className="container">
					<div className="clearfix">
						<div className="grid_12">
							<div className="page-title-holder">
								<h1>Ron Wilson in Architectural Digest</h1>
							</div>
							
							<ul className="breadcrumbs">
								<li><a href="index.php">Home</a></li>
								<li><a href="press.php">Press</a></li>
								<li>Architectural Digest</li>
							</ul>
							
						</div>
					</div>
				</div>
			</div>
			
			
			
			
			<div id="content-wrapper" className="content-wrapper">
				<div className="container">
					
					<div className="clearfix">
						<div className="grid_12">
							
			           
			            
							
			            <div className="clearfix">
			            	<div className="grid_5 alpha">
			            		
									<div className="flexslider project-thumbs flexslider__nav-on">
										<ul className="slides">
											<li><img src="images/press/2000-01-m.jpg" height="500" width="" alt=""/></li>
										</ul>
									</div>
									
			            	</div>
			            	<div className="grid_6 omega">
			            		
			            		<div className="project-desc project-desc__single">
										<h3 className="title"><a href="#">Architectural Digest</a></h3>
										<span className="desc">January, 2000</span>
										<div className="project-excerpt">
											<p>For the January 2000 edition, Architectural Digest once again published its revered list of the Top 100 Architects and Interior Designers respectfully entitled “Interior Design Legends.” An interview of Ron Wilson revealing his reverence for the classicist Billy Baldwin can be seen on page 132.</p> 
										</div>
										
										
									</div>
									
			            	</div>
			            </div>

			            <div className="hr"></div>


			            
						</div>
					</div>

				</div>
			</div>
  </>
);

export default Comp;
