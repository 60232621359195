import Projects from "./Projects";

const Home = () => (
  <>
  <section id="slider" className="slider">
    <div className="fullwidthbanner-container">
      <div className="fullwidthbanner">
        <ul>

							<li data-transition="slideup">
            <img src="images/slide1-ron-wilson-interior-design.jpg"/>
								
							</li>
   
							<li data-transition="slidedown">
              <img src="images/slide2-ron-wilson-interior-design.jpg"/>
								
							</li>
 
							<li data-transition="slidedown">
                <img src="images/slide5-ron-wilson-interior-design.jpg" />
								
							</li>
  
						</ul>
					</div>
				</div>
			</section>

			<div id="content-wrapper" className="content-wrapper">
          <div className="container">

					<div className="quote-holder clearfix">
              <div className="grid_12 mobile-nomargin">
                <blockquote className="bq-alt">
                  <p align="center"><a href="about.php">Creating</a> Very Special <a href="portfolio.php">Living Spaces</a> for Very Special People</p>
                </blockquote>
              </div>
            </div>

					<div className="hr hr__double">
              <div className="hr-first"></div>
              <div className="hr-second"></div>
            </div>

					<Projects />

					<div className="hr hr__double">
              <div className="hr-first"></div>
              <div className="hr-second"></div>
            </div>


				</div>

        </div>
</>
);

export default Home;
