const Comp = () => (
  <>
			<div id="page-title" className="page-title">
				<div className="container">
					<div className="clearfix">
						<div className="grid_12">
							<div className="page-title-holder">
								<h1>Ron Wilson in Architectural Digest</h1>
							</div>
							
							<ul className="breadcrumbs">
								<li><a href="index.php">Home</a></li>
								<li><a href="press.php">Press</a></li>
								<li>Architectural Digest</li>
							</ul>
							
						</div>
					</div>
				</div>
			</div>
			
			
			
			
			<div id="content-wrapper" className="content-wrapper">
				<div className="container">
					
					<div className="clearfix">
						<div className="grid_12">
							
			           
			            
							
			            <div className="clearfix">
			            	<div className="grid_5 alpha">
			            		
									<div className="flexslider project-thumbs flexslider__nav-on">
										<ul className="slides">
											<li><img src="images/press/2007-08-m.jpg" height="500" width="" alt=""/></li>
										</ul>
									</div>
									
			            	</div>
			            	<div className="grid_6 omega">
			            		
			            		<div className="project-desc project-desc__single">
										<h3 className="title"><a href="#">Architectural Digest</a></h3>
										<span className="desc">August, 2007</span>
										<div className="project-excerpt">
											<p>In August 2007, another extraordinary design project by Ron Wilson & his partner, Joe Guidera, was featured. This time, a magnificent Oceanfront estate, on the island of Maui was offered for view by Architectural Digest on pages 91 - 99. Years spent in new construction revealed a home replete with all of the feeling of Old Hawaiiana.</p>
<p>Extraordinary comfortable furniture, fabrics and accessories play host to island breezes and surreal tropical vistas.</p> 
										</div>
										
										
									</div>
									
			            	</div>
			            </div>

			            <div className="hr"></div>


			            
						</div>
					</div>

				</div>
			</div>
  </>
);

export default Comp;
